@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
    padding: 0.5rem;
    scroll-margin-top: 100px;
    flex: 1;
    &.highlighted {
        background-color: lightgray;
    }

    .details {
        border-top: dotted 1px #ccc;
        margin-top: 10px;
    }
}

.isOpenable {
    cursor: pointer;
}
